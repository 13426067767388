import * as sentry from '@sentry/browser'
import {
  getAppUrl,
  getAppNameWithVersion,
} from '@wix/wix-data-client-common-standalone'
import { APP_NAME } from '../../helpers/constants'
import type { Platform } from '../Platform'

export const createSentryNextClient = ({
  dsn,
  platform,
}: {
  dsn: string
  platform: Platform
}) => {
  const appUrl = getAppUrl(APP_NAME)
  const appVersion = getAppNameWithVersion(appUrl)
  const sentryClient = new sentry.BrowserClient({
    dsn,
    release: `${APP_NAME}@${appVersion}`,
  })
  const sentryNext = new sentry.Hub(sentryClient)

  sentryNext.setUser({ id: platform.user.id })

  const tags = {
    msid: platform.settings.metaSiteId,
    url: (platform.location.pageUrl || '').slice(0, 200),
    viewMode: platform.settings.mode.name,
    renderMode: platform.settings.env.name,
  }

  sentryNext.setTags(tags)

  return sentryNext
}
